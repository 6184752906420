<template>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header row">
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-block btn-danger" @click="save()" v-if="!ask._id && loaded">
              <i class="fa fa-save"></i>
              حفظ في حساب المعلم
            </button>
            <button class="btn btn-block btn-outline-success" @click="save()" v-if="ask._id">
              <i class="fa fa-check"></i>
              تم الحفظ في حساب المعلم
            </button>
          </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-block btn-success" v-if="loaded" @click="save(); print()">
              <i class="fa fa-print"></i>
              طباعة المساءلة
            </button>
          </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-block btn-primary" v-if="loaded" @click="save(); generateMessage()" v-b-modal.modal-2>
              <i class="fa fa-paper-plane"></i>
              حفظ 
              وإرسال للمعلم
            </button>
          </div>
      </div>
      <div class="card-body">
        <ckeditor v-if="loaded" :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
        <div v-if="ask.teacher_reply">
          <br>رد المعلم: {{ ask.teacher_reply }}
        </div>
      </div>
    </div>
  </div>
  <b-modal id="modal-2" title="ارسال" hide-footer>
    <div class="col-12">
        <div class="form-group">
          <label for="">نص الرسالة المرسلة</label>
          <textarea class="form-control" v-model="message" rows="3" @change="messageChanged()"></textarea>
        </div>
    </div>
    <div class="col-12">
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" @change="save()" v-model="allow_teacher_reply" :value="true">
          السماح للمعلم بتقديم افادة او الرد على المساءلة
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4 g">
          <button class="btn btn-success btn-block"  @click="send('whatsapp')">
              <i class="fa fa-whatsapp"></i>
                واتس تلقائي
          </button>
      </div>
      <div class="col-12 col-lg-4 g">
          <button class="btn btn-success btn-block" @click="send('wa')">
              <i class="fa fa-whatsapp"></i>
              اداة المتصفح
          </button>
      </div>
      <div class="col-12 col-lg-4 g">
          <button class="btn btn-primary btn-block"  @click="send('sms')">
              <i class="fa fa-mobile"></i>
              SMS
          </button>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const moment = require("moment-hijri");
import Ripple from "vue-ripple-directive";
import {
  VBModal
} from "bootstrap-vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data(){
    return {
      message: ``,
      ask: {},
      teacher: {},
      editor: ClassicEditor,
      allow_teacher_reply: true,
      number: window.location.href.split("number=")[1].split("&")[0],
      user: JSON.parse(localStorage.getItem('user')),
      editorData: `جاري التحميل...`,
      loaded: false,
      info: {},
      typex: "",
      date: window.location.href.split("date=")[1].split("&")[0],
      editorConfig: {
          language: 'ar'
      },
      ttype: window.location.href.split("type=")[1].split("&")[0],
      types: [
        "عدم تواجد داخل الفصل",
        "خروجك من الحصة قبل انتهاء الوقت",
        "عدم تحضير الدروس",
        "عدم المناوبة في الفسحة",
        "عدم المناوبة في الممرات",
        "عدم المناوبة في نهاية الدوام",
        "عدم الحضور لحصص الانتظار"
      ],
      type: window.location.href.includes("other") ? new Number(window.location.href.split("other-")[1].split("&")[0]) - 1 : 0
    }
  },
  created(){
    var g = this;
    var date = window.location.href.split("date=")[1].split("&")[0];
    var days = [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الاربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ];
    var d = new Date(date);
    var day = days[d.getDay()];
    date = moment(date, 'YYYY-MM-DD').format('iD-iM-iYYYY');
    date =
      date + "هـ";
    $.post(api + '/user/teachers/teacher', {
      jwt: g.user.jwt,
      number: window.location.href.split("number=")[1].split("&")[0]
    }).then(function(r){
      var teacher = r.response;
      g.teacher = teacher;
      $.post(api + '/user/teachers/info', {
        jwt: g.user.jwt
      }).then(function(r){
        $.post(api + '/user/teachers/asks/ask', {
          jwt: g.user.jwt,
          number: window.location.href.split("number=")[1].split("&")[0],
          type: g.ttype,
          date: g.date
        }).then(function(r){
          g.loaded = true;
          if(r?.response?.user_id){
            g.ask = r.response
            g.editorData = r.response.html;
            g.allow_teacher_reply = r.response.allow_teacher_reply;
            if(r.response.teacher_reply){
              g.editorData = g.editorData.replace(".................................................................... .................................................................... ..................................................", r.response.teacher_reply);
              g.editorData = g.editorData.replace(".................................................................... ....................................................................", r.response.teacher_reply);
              g.editorData = g.editorData.replaceAll(".................................................................... .................................................................... ..................................................", "");
            }
          }
        })
        var info = r.response;
        g.info = info
        if(window.location.href.includes("type=late") && !window.location.href.includes("type=lated")){
          g.typex = "مسائلة تأخر"
          g.editorData = `
            <table class="table table-bordered">
            <thead>
              <th class="a"><span>المدرسة</span></th>
              <th class="fullInputTH">
                ${info?.school_name}
              </th>
            </thead>
            <thead>
              <th class="a"><span>السجل المدني</span></th>
              <th class="fullInputTH" style="width: 80% !important">
                ${teacher.number}
              </th>
            </thead>
          </table>
          <table class="table table-bordered">
          <thead>
            <th class="a"><span>الاسم</span></th>
            <th class="a"><span>التخصص</span></th>
            <th class="a"><span>المستوى \ الرتبة</span></th>
            <th class="a"><span>رقم الوظيفة</span></th>
            <th class="a"><span>العمل الحالي</span></th>
          </thead>
          <tbody>
            <tr>
              <td class="fullInputTH" style="width: 300px">
                ${teacher.name}
              </td>
              <td class="fullInputTH">
                ${teacher?.ta5asos ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.level ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.job_number ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.current_job ?? ''}
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <strong>المكرم/ ${teacher.name} وفقه الله</strong>
        <br>
        السلام عليكم ورحمة الله وبركاته وبعد ،،،
        <br>
        إنه في يوم ${day} الموافق ${date} اتضح ما يلي:
        <br><br>
        تأخركم عن بداية العمل، وحضوركم الساعة (${decodeURIComponent(window.location.href.split('time=')[1].split('&')[0]).split(" ")[1]})
        <br>
        عليه نأمل توضيح اسباب ذلك مع ارفاق ما يؤيد عذركم،،، ولكم تحياتي
        <br><br>
        مدير المدرسة: 
        ${info?.admin_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        التوقيع                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     التاريخ ${date}
      <br><br>
        المكرم/ مدير المدرسة 
        وفقه الله
<br><br>
        السلام عليكم ورحمة الله وبركاته وبعد:
        <br>
        افيدكم ان اسباب ذلك ما يلي:
<br><br>
        .................................................................... .................................................................... ..................................................
        <br>
        .................................................................... .................................................................... ..................................................
<br><br>
        الاسم: ${teacher.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    التوقيع:            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;             التاريخ ${date}
<br><bR>
        رأي مدير المدرسة:                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                عذره مقبول           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                      عذره غير مقبول ويحسم عليه
<br><bR>
        مدير المدرسة: 
        ${info?.admin_name}            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;              
        التوقيع                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   التاريخ ${date}
  <br><br>
        ملاحظة: ترفق بطاقة المساءلة مع أصل القرار في حالة عدم قبول العذر لحفظها بملفه بالإدارة، وأصله لملفه بالمدرسة.
          `
        }
        if(window.location.href.includes("type=ghiab")){
          g.typex = "مسائلة غياب"
          g.editorData = `
            <table class="table table-bordered">
            <thead>
              <th class="a"><span>المدرسة</span></th>
              <th class="fullInputTH">
                ${info?.school_name}
              </th>
            </thead>
            <thead>
              <th class="a"><span>السجل المدني</span></th>
              <th class="fullInputTH" style="width: 80% !important">
                ${teacher.number}
              </th>
            </thead>
          </table>
          <table class="table table-bordered">
          <thead>
            <th class="a"><span>الاسم</span></th>
            <th class="a"><span>التخصص</span></th>
            <th class="a"><span>المستوى \ الرتبة</span></th>
            <th class="a"><span>رقم الوظيفة</span></th>
            <th class="a"><span>العمل الحالي</span></th>
          </thead>
          <tbody>
            <tr>
              <td class="fullInputTH" style="width: 300px">
                ${teacher.name}
              </td>
              <td class="fullInputTH">
                ${teacher?.ta5asos ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.level ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.job_number ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.current_job ?? ''}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
        <br>
        إنه في يوم ${day} الموافق ${date} تغيبت عن العمل إلى اليوم ${day} الموافق ${date}

        </h3>
        <h2 style="color: #35858b">(1) طلب الإفادة</h2>
      <h3>
        المكرم/ ${teacher.name}
        وفقه الله
      </h3>
      <h3>
        السلام عليكم ورحمة الله وبركاته وبعد ،،،
        <br />
        من خلال متابعة سجل العمل تبين غيابكم خلال الفترة الموضحة بعاليه،
        <br />
        آمل الإفادة عن أسباب ذلك وعليكم تقديم ما يؤيد عذركم خلال اسبوع من
        تاريخه،<br />
        علماً بأنه في حالة عدم الالتزام سيتم اتخاذ اللازم حسب التعليمات.
      </h3>
      <h3>
        اسم الرئيس المباشر:
        ${info?.boss_name}&nbsp;&nbsp;&nbsp;
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
        <br />
      </h3>
      <h2 style="color: #35858b">(2) الإفادة</h2>
      <h3>
        المكرم/ مدير المدرسة
        ${info?.admin_name}
        وفقه الله <br /><br />
        السلام عليكم ورحمة الله وبركاته وبعد:
        <br />
        أفيدكم أن غيابي كان للأسباب التالية:
        <br /><br />
        .................................................................... .................................................................... 
        <br>
        .................................................................... .................................................................... 
      </h3>
      <h3>
        وسأقوم بتقديم ما يثبت ذلك خلال أسبوع من تاريخه
        <br />
        اسم المعلم: ${teacher.name} &nbsp;&nbsp;&nbsp; التوقيع:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
      </h3>
      <h2 style="color: #35858b">(3) مدير المدرسة:</h2>
      <ul>
        <li>
          <h3>أ- تحتسب له إجازة مرضية بعد التأكد من نظامية التقرير.</h3>
        </li>
        <li>
          <h3>
            ب- يحتسب غيابه من رصيده للإجازات الاضطرارية لقبول عذره إذا كان رصيده
            يسمح وإلا يحسم عليه.
          </h3>
        </li>
        <li>
          <h3>ج- يعتمد الحسم لعدم قبول عذره.</h3>
        </li>
      </ul>
      <h3>
        اسم الرئيس المباشر:
        ${info?.boss_name}
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
        <br />
      </h3>
      <h2 style="color: #35858b">ملحوظات هامة</h2>
      <h3>
        <ol>
          <li>
            تستكمل الاستمارة من المدير مباشرة وإصدار القرار بموجبه.
          </li>
          <li>
              إذا سبق إجازة نهاية الأسبوع غياب وألحقها غياب تحتسب مدة الغياب
              كاملة.
          </li>
          <li>
              يجب أن يوضح المتغيب أسباب غيابه فور تسليمه الاستمارة ويعيدها
              لمديره المباشر.
          </li>
          <li>
              يعطي المتغيب مدة أسبوع لتقديم ما يؤيد عذره فإذا انقضت المدة
              الزمنية تستكمل الاستمارة ويتم الحسم.
          </li>
        </ol>
      </h3>
          `
        }
        if(window.location.href.includes("type=dis")){
          var ddays = "", ddays2 = "", ddays3 = "";
          window.location.href.split("date=")[1].split("&")[0].split(",").forEach(function(a,i){
            a = moment(a, 'YYYY-MM-DD').format('iD-iM-iYYYY');
            if(i < 4){
              ddays = ddays + `<td class="a"><span>${a}هـ</span></td>`
            }else{
              if(i < 8){
                ddays2 = ddays2 + `<td class="a"><span>${a}هـ</span></td>`
              }else{
                ddays3 = ddays3 + `<td class="a"><span>${a}هـ</span></td>`
              }
            }
          })
          g.typex = "قرار حسم غياب"
          g.editorData = `
            <table class="table table-bordered">
            <thead>
              <th class="a"><span>المدرسة</span></th>
              <th class="fullInputTH">
                ${info?.school_name}
              </th>
            </thead>
            <thead>
              <th class="a"><span>السجل المدني</span></th>
              <th class="fullInputTH" style="width: 80% !important">
                ${teacher.number}
              </th>
            </thead>
          </table>
          <table class="table table-bordered">
          <thead>
            <th class="a"><span>الاسم</span></th>
            <th class="a"><span>التخصص</span></th>
            <th class="a"><span>المستوى \ الرتبة</span></th>
            <th class="a"><span>رقم الوظيفة</span></th>
            <th class="a"><span>العمل الحالي</span></th>
          </thead>
          <tbody>
            <tr>
              <td class="fullInputTH" style="width: 300px">
                ${teacher.name}
              </td>
              <td class="fullInputTH">
                ${teacher?.ta5asos ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.level ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.job_number ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.current_job ?? ''}
              </td>
            </tr>
            <tr>
            <td class="a"><span>الايام الواجب حسمها ليحدد تاريخها</span></td>
            ${ddays}
            </tr>
            ${ddays2 !='' ? `
            <tr>
            <td class="a"><span></span></td>
            ${ddays2}
            </tr>
            ` : ''}
            ${ddays3 != '' ? `
            <tr>
            <td class="a"><span></span></td>
            ${ddays3}
            </tr>
            ` : ''}
          </tbody>
        </table>
        <h3>
        أن قائد المدرسة ....................................................................
        <br>
        بناء على صلاحياته، وبناء على المادة (21) من نظام الخدمة المدنية، وبناء على 
        موافقة معالي الوزير على إعطاء بعض الصلاحيات لمديري المدارس بالقرار رقم 1/1139 وتاريخ 1421/3/17هـ,
        ولغياب المعلم الموضح اسمه اعلاه، وحيث ان عذره غير مقبول ،  وبمقتضى النظام.
        <br><br>
        يقرر ما يلي:
        <br>
        [1] حسم مدة الغياب الموضحة وعددها (&nbsp;&nbsp;&nbsp;${window.location.href.split("date=")[1].split("&")[0].split(",").length}&nbsp;&nbsp;&nbsp;) يوماً من راتبه.
        <br>
        [2] على إدارة شؤون الموظفين [تنفيذ الانظمة] تنفيذ إجراء الحسم واستبعادها من خدماته 
        واصل القرار لملفه بالادارة مع الاساس لملفه (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; والله الموفق،،،
        </h3>
        <div style='margin-right: 200px !important;'><h3 style=''>
          الرئيس المباشر <br>
          الاسم: ${info?.boss_name ?? ''}<br>
          التوقيع:
          <br>
          التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;14 هـ
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; الختم
        </h3></div>
        <h3>
          ملاحظة/ لن يتم استلام قرار الحسم بدون المساءلة
          <br>
          صورة / للموظفين لمتابعة تنفيذ الحسم {تنفيذ الانظمة}.
          <br>
          صورة لمكتب التربية والتعليم.<br>
          صورة / لمشرف المنسق.
          <br>
          صورة / لملفه بالمدرسة.
        </h3>
          `
        }
        if(window.location.href.includes("type=latedis")){
          var ddays = "", ddays2 = "", ddays3 = "";
          g.typex = "قرار حسم مجموع ساعات تأخر وخروج مبكر"
          g.editorData = `
            <table class="table table-bordered">
            <thead>
              <th class="a"><span>المدرسة</span></th>
              <th class="fullInputTH">
                ${info?.school_name}
              </th>
            </thead>
            <thead>
              <th class="a"><span>السجل المدني</span></th>
              <th class="fullInputTH" style="width: 80% !important">
                ${teacher.number}
              </th>
            </thead>
          </table>
          <table class="table table-bordered">
          <thead>
            <th class="a"><span>الاسم</span></th>
            <th class="a"><span>التخصص</span></th>
            <th class="a"><span>المستوى \ الرتبة</span></th>
            <th class="a"><span>رقم الوظيفة</span></th>
            <th class="a"><span>العمل الحالي</span></th>
          </thead>
          <tbody>
            <tr>
              <td class="fullInputTH" style="width: 300px">
                ${teacher.name}
              </td>
              <td class="fullInputTH">
                ${teacher?.ta5asos ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.level ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.job_number ?? ''}
              </td>
              <td class="fullInputTH">
                ${teacher?.current_job ?? ''}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
        أن قائد المدرسة ....................................................................
        <br>
        بناء على صلاحياته، وبناء على المادة (21) من نظام الخدمة المدنية، وبناء على 
        موافقة معالي الوزير على إعطاء بعض الصلاحيات لمديري المدارس بالقرار رقم 1/1139 وتاريخ 1421/3/17هـ,
        ولبلوغ ساعات التأخر عن الدوام والخروج المبكر من الدوام (&nbsp;&nbsp;${window.location.href.split('hours=')[1].split("&")[0]}&nbsp;&nbsp;)
        ساعة وحيث ان عذره غير مقبول ،وبمقتضى النظام.
        <br><br>
        يقرر ما يلي:
        <br>
        [1] حسم مدة الغياب الموضحة وعددها (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) يوماً من راتبه.
        <br>
        [2] على إدارة شؤون الموظفين [تنفيذ الانظمة] تنفيذ إجراء الحسم واستبعادها من خدماته 
        واصل القرار لملفه بالادارة مع الاساس لملفه (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; والله الموفق،،،
        </h3>
        <div style='margin-right: 200px !important;'><h3 style=''>
          الرئيس المباشر <br>
          الاسم: ${info?.boss_name ?? ''}<br>
          التوقيع:
          <br>
          التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;14 هـ
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; الختم
        </h3></div>
        <h3>
          ملاحظة/ لن يتم استلام قرار الحسم بدون المساءلة
          <br>
          صورة / للموظفين لمتابعة تنفيذ الحسم {تنفيذ الانظمة}.
          <br>
          صورة لمكتب التربية والتعليم.<br>
          صورة / لمشرف المنسق.
          <br>
          صورة / لملفه بالمدرسة.
        </h3>
          `
        }
        if(window.location.href.includes("other")){
          g.typex = g.types[g.type]
          g.editorData = `
          <center><h2>مساءلة معلم</h2></center>
    <div class="g" style="padding-right: 20px">
      <h3 class="text-center"> 
        المكرم/ ${teacher.name}
        وفقه الله &nbsp;&nbsp;
        السلام عليكم ورحمة الله وبركاته وبعد ،،،
        <br /><br />
        انه في يوم ${day} الموافق ${date} اتضح ما يلي:
      </h3>
      <h3>
        ${g.types[g.type]} &nbsp; &nbsp; ....................................................................
      </h3>
      <h3>
        عليه نأمل توضيح اسباب ذلك مع ارفاق ما يؤيد عذركم ٫٫٫ ولكم تحياتي..
      </h3>
      <h3>
        مدير المدرسة:
        ${info?.admin_name}
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
      </h3>
      <br />
      <h3>
        المكرم/ مدير المدرسة
        ${info?.admin_name}
        وفقه الله <br /><br />
        السلام عليكم ورحمة الله وبركاته وبعد:
        <br />
        افيدكم ان اسباب ذلك ما يلي:
        <br /><br />
        .................................................................... .................................................................... ..................................................
        <br>
        .................................................................... .................................................................... ..................................................
        <br /><br />
        الاسم: ${teacher.name} &nbsp;&nbsp;&nbsp; التوقيع:
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
      </h3>
      <h3>
        رأي مدير المدرسة: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; عذره مقبول
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; عذره غير مقبول ويحسم عليه
      </h3>
      <h3>
        مدير المدرسة:
        ${info?.admin_name}
        التوقيع &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; التاريخ
        ${date}
      </h3>
      <h3>
        ملاحظة: ترفق بطاقة المساءلة مع أصل القرار في حالة عدم قبول العذر لحفظها
        بملفه بالإدارة، وأصله لملفه بالمدرسة.
      </h3>
          `
        }
      })
    })
  },
  methods: {
    save(){
      var g = this;
      $.post(api + '/user/teachers/asks/save', {
        jwt: g.user.jwt,
        number: window.location.href.split("number=")[1].split("&")[0],
        type: g.ttype,
        date: g.date,
        hours: window?.location?.href?.split("hours=")[1]?.split("&")[0],
        html: g.editorData,
        allow_teacher_reply: this.allow_teacher_reply?.toString()
      }).then(function(r){
        g.ask = r.response;
      })
    },
    print(){
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html lang='ar'><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
              background: #D6EFD8
          }
          table th, table td
          {
              border: 1px solid #ccc;
          }
          td{
            background: #E7F0DC
          }
        `);
      printWindow.document.designMode = "on";
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      printWindow.document.write(`
      <body>

          <div style='width: 100%;'>
        <div style='width: 30%; text-align: center; display: inline-block;'>
          <h3>
            المملكة العربية السعودية
            <br>
            وزارة التعليم
            <br>
            ${g.info?.school_name}
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
        <img style="width: 150px !important" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          المسائلة: ${g.typex}
          <br><br><br>
          </h3>
        </div>
        <hr>
      </div>
        ${this.editorData}
      </body>
      `);
      printWindow.document.close()
      setTimeout(function(){
        printWindow.print()
      }, 1000)
    },
    generateMessage(){
      var g = this;
      g.message = `المعلم ${g.teacher.name}
برجاء الاطلاع على المساءلة من الرابط التالي
{link}`
    },
    send(method){
        var g = this;
        var messages = [];
        g.save()
        var x = setInterval(() => {
          if(g?.ask?._id){
            clearInterval(x)
            messages.push({
                phones: [g.teacher.phone],
                message: g.message.replace("{link}", `({https://s.tahdir.net/teacher-ask/${g.ask._id}})`)
            })
            $.post(api + '/user/general/short-links', {
                jwt: this.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("results")
                    $("#sendWAModal").click()
                }else{
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "teachers"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                    alert("حدث خطأ.", 200)
                })
          }
        }, 10);
    }
  }
}
</script>
<style>
div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__top.ck-reset_all > div > div.ck.ck-sticky-panel__content > div > div > button.ck.ck-button.ck-off.ck-file-dialog-button{
  display: none;
}
div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__top.ck-reset_all > div > div.ck.ck-sticky-panel__content > div > div > div:nth-child(11){
  display: none;
}
div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__top.ck-reset_all > div > div.ck.ck-sticky-panel__content > div > div > button:nth-child(12){
  display: none;
}
div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__top.ck-reset_all > div > div.ck.ck-sticky-panel__content > div > div > div:nth-child(13) > button{
  display: none;
}
div.ck.ck-reset.ck-editor.ck-rounded-corners > div.ck.ck-editor__top.ck-reset_all > div > div.ck.ck-sticky-panel__content > div > div > button:nth-child(9){
  display: none;
}
</style>